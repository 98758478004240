
import Vue, { VueConstructor } from "vue";

import FormMixins from "@/mixins/form";
import CattleLot from "@/models/cattleLot";
import { IEnums } from "@/types/form-types";
import { isValidKeys, setValueValidKey } from "@/utilsObject";
import { crudService } from "@/services/crud";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof FormMixins>>
).extend({
  props: {
    ownership_id: Number,
    gColor: String,
  },
  mixins: [FormMixins],
  data() {
    return {
      // main object v-model
      cattle_lot: new CattleLot(),
      // common label for kilos
      kilo: "kg",
      // common label for day
      day: "dia",
      // common label for days
      days: "dias",
      // Enum gender
      genders: [
        {
          name: "Macho",
          value: "MALE",
        },
        {
          name: "Fêmea",
          value: "FEMALE",
        },
      ] as IEnums[],
    };
  },
  methods: {
    recalcFields(): void {
      // Calculo peso final da recria
      if (
        this.cattle_lot.dayly_weight_gain_breed &&
        this.cattle_lot.breed_period &&
        this.cattle_lot.initial_thin_weight
      ) {
        // peso final recria = (g.p.d-recria * periodo recria) + peso inicial de recria
        let total_of_period =
          this.cattle_lot.breed_period *
          this.cattle_lot.dayly_weight_gain_breed;
        this.cattle_lot.final_weight_breed =
          this.cattle_lot.initial_thin_weight + total_of_period;
      }
      // Calcula data inicial da terminação
      if (this.cattle_lot.breed_period) {
        // Soma data inicial recria com periodo de recria = inicio terminacao
        if (this.cattle_lot.initial_date_breed)
          this.cattle_lot.initial_date_finishing = this.$luxon(
            this.dateISONotNull(this.cattle_lot.initial_date_breed, false)
          )
            .plus({ days: this.cattle_lot.breed_period })
            .toString()
            .substr(0, 10);
      }
      // Calcula a data de abate
      if (this.cattle_lot.finishing_period) {
        // Soma inicio terminacao com periodo terminicao = data abate
        this.cattle_lot.slaughter_date = this.$luxon(
          this.dateISONotNull(this.cattle_lot.initial_date_finishing, false)
        )
          .plus({ days: this.cattle_lot.finishing_period })
          .toString()
          .substr(0, 10);
      }
      // Calcula o peso final de abate em kg
      // peso final terminacao = (g.p.d-terminacao * periodo terminacao) + peso inicial terminicao
      if (this.cattle_lot.cycle === "BREED_FINISHING") {
        if (
          this.cattle_lot.dayly_weight_gain_finishing &&
          this.cattle_lot.finishing_period &&
          this.cattle_lot.final_weight_breed
        ) {
          let total_of_period =
            this.cattle_lot.finishing_period *
            this.cattle_lot.dayly_weight_gain_finishing;
          this.cattle_lot.final_weight_finishing =
            this.cattle_lot.final_weight_breed + total_of_period;
        }
      } else {
        if (
          this.cattle_lot.dayly_weight_gain_finishing &&
          this.cattle_lot.finishing_period &&
          this.cattle_lot.initial_weight_finishing
        ) {
          let total_of_period =
            this.cattle_lot.finishing_period *
            this.cattle_lot.dayly_weight_gain_finishing;
          this.cattle_lot.final_weight_finishing =
            this.cattle_lot.initial_weight_finishing + total_of_period;
        }
      }
      // Calcula o peso final da carcaça em arrobas
      if (
        this.cattle_lot.final_weight_finishing &&
        this.cattle_lot.yield_carcass
      ) {
        this.cattle_lot.final_weight =
          (this.cattle_lot.final_weight_finishing *
            (this.cattle_lot.yield_carcass / 100)) /
          15; // Arrobas
      }
    },
    manualInputDate(date: string, prop: string): void {
      if (isValidKeys(CattleLot, prop)) {
        this.cattle_lot = setValueValidKey(this.cattle_lot, prop, date);
      }
    },
    async save(): Promise<void> {
      if (this.validate()) {
        this.cattle_lot.ownership_id = this.ownership_id;
        if (this.cattle_lot.initial_date_breed)
          this.cattle_lot.initial_date_breed = this.dateISONotNull(
            this.cattle_lot.initial_date_breed
          );
        this.cattle_lot.initial_date_finishing = this.dateISONotNull(
          this.cattle_lot.initial_date_finishing
        );
        this.cattle_lot.slaughter_date = this.dateISONotNull(
          this.cattle_lot.slaughter_date
        );
        this.cattle_lot.total_weight_lot =
          this.cattle_lot.final_weight_finishing * this.cattle_lot.amount;

        try {
          const cattleLot = await crudService.saveClass(
            CattleLot,
            CattleLot.getResource(),
            this.cattle_lot
          );
          this.$alertSuccess(this.componentsText.mSuccess);
          this.$emit("updateResourceList", cattleLot);
          this.close();
        } catch (error) {
          this.$alertError(this.componentsText.mError);
          console.error(error);
        }
      }
    },
    toUpdate(item: CattleLot): void {
      this.show = true;
      this.$nextTick(() => {
        this.cattle_lot = new CattleLot(item);
      });
    },
    initialize(): void {
      this.cattle_lot = new CattleLot();
    },
    updateCycleInfo(): void {
      if (this.cattle_lot.cycle === "BREED_FINISHING") {
        this.cattle_lot.initial_weight_finishing = undefined;
      } else {
        this.cattle_lot.initial_date_breed = undefined;
        this.cattle_lot.initial_thin_weight = undefined;
        this.cattle_lot.breed_period = undefined;
        this.cattle_lot.dayly_weight_gain_breed = undefined;
        this.cattle_lot.final_weight_breed = undefined;
      }
    },
  },
  computed: {
    componentsText() {
      if (this.cattle_lot && this.cattle_lot.id) {
        return {
          buttonText: "Atualizar",
          mSuccess: "Lote atualizado com sucesso!",
          mError: "Houve um problema ao atualizar o lote",
        };
      } else {
        return {
          buttonText: "Adicionar",
          mSuccess: "Lote inserido com sucesso!",
          mError: "Houve um problema ao inserir o lote",
        };
      }
    },
    finishingFinalWeightKilos(): number | undefined {
      if (
        this.cattle_lot.final_weight_finishing &&
        this.cattle_lot.yield_carcass
      ) {
        return (
          this.cattle_lot.final_weight_finishing *
          (this.cattle_lot.yield_carcass / 100)
        );
      }
      return undefined;
    },
  },
});
