
import Vue, { VueConstructor } from "vue";

import CattleForm from "./forms/CattleForm.vue";
import CrudMixins from "@/mixins/crud";
import Ownership from "@/models/ownership";
import { IFormFuncs, ItemDataTable } from "@/types/form-types";
import { IParams } from "@/types/store-types";
import { crudService } from "@/services/crud";
import CattleLot, { CattleLotExtraInfo } from "@/models/cattleLot";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CrudMixins>>
).extend({
  components: {
    CattleForm,
  },
  mixins: [CrudMixins],
  data() {
    return {
      // open form
      dialog: false,
      // Propriedade selecionada
      ownershipSelected: undefined as number | undefined,
      // disable btn de add
      // se não tem propriedade
      disableAdd: false,
      // array de propriedades
      ownerships: [] as Ownership[],
      // item para sort
      sortBy: "slaughter_date",
      // deve ordenar
      sortDesc: true,
      // true quando o user tem lots expirados
      hasExpiredLots: false,
      // dialog de aviso de lots expirados
      showWarningMsg: false,
      // headers da tabela
      headers: [
        {
          text: "Nome do Lote",
          tooltip: "Nome registrado do Lote",
          align: "left",
          value: "name",
        },
        {
          text: "Animais",
          tooltip: "Quantidade de animais",
          align: "center",
          value: "amount",
        },
        {
          text: "Ciclo",
          tooltip: "Ciclo do lote",
          align: "center",
          value: "cycle",
        },
        {
          text: "Data de início",
          tooltip: "Data de entrada do animal",
          align: "center",
          value: "initial_date_breed",
        },
        {
          text: "Data do abate",
          tooltip: "Data de saída do animal",
          align: "center",
          value: "slaughter_date",
        },
        {
          text: "Peso final terminação",
          tooltip: "Peso final do animal para o abate",
          align: "center",
          value: "final_weight_finishing",
        },
        {
          text: "Peso final carcaça",
          tooltip: "Peso final do rendimento da carcaça",
          align: "center",
          value: "final_weight",
        },
        {
          text: "Ações",
          align: "center",
          tooltip: "Modificar ou excluir o talhão",
          value: "actions",
          sortable: false,
        },
      ] as ItemDataTable[],
    };
  },
  async mounted() {
    this.localLoading = true;
    let ownership_id = 0;
    if (this.$route.params.ownership_id) {
      ownership_id = parseInt(this.$route.params.ownership_id);
    }
    try {
      this.ownerships = await this.myOwnerships();
      if (this.ownerships && this.ownerships.length > 0) {
        if (ownership_id) {
          const ownership = this.ownerships.find((o) => o.id === ownership_id);
          if (ownership) this.ownershipSelected = ownership.id;
        } else {
          this.ownershipSelected = this.ownerships[0].id;
        }
        await this.searchCattleLots();
        // se tem lots expirados, mostra a msg
        if (this.hasExpiredLots) this.showWarningMsg = true;
      } else {
        this.disableAdd = true;
      }
    } catch (error) {
      console.error(error);
      this.$alertError("Houve um problema ao carregar as propriedades");
    }
  },
  methods: {
    async searchCattleLots(): Promise<void> {
      this.localLoading = true;
      const params: IParams = {
        ownership_id: this.ownershipSelected,
        checkDelete: true,
      };
      try {
        this.results = await crudService.getResourceClass(
          CattleLot,
          CattleLot.getResource(),
          params
        );
      } catch (error) {
        this.$alertError("Erro ao carregar os talhões");
      } finally {
        this.localLoading = false;
      }
    },
    edit(ucattle: CattleLotExtraInfo): void {
      const form = this.$refs["form"] as IFormFuncs<CattleLot>;
      if (form && form.toUpdate) form.toUpdate(new CattleLot(ucattle));
    },
    confirmDelete(dcattle: CattleLotExtraInfo): void {
      this.setResource("cattle_lot");
      delete dcattle.tooltip;
      delete dcattle.color;

      dcattle.is_deleted = true;
      dcattle.deleted_date = new Date().toISOString();
      this.update(dcattle)
        .then(() => {
          this.$alertSuccess("Lote deletedo com sucesso!");
          this.removeFromResourceList(this.results.objects, dcattle);
        })
        .catch(() => {
          this.$alertError("Houve um problema ao deletar o Lote!");
        });
    },
    dupCattle(dupCattle: CattleLotExtraInfo): void {
      delete dupCattle.tooltip;
      delete dupCattle.color;

      this.duplicate(dupCattle, "cattle_lot");
    },
    enumCycle(cycle: string): string {
      if (cycle === "BREED_FINISHING") return "Recria e terminação";
      return "Terminação";
    },
    insertColor(item: CattleLot): CattleLotExtraInfo {
      const today = new Date();
      const when = new Date(item.slaughter_date);
      const diffInDays = this.getDifferenceInDays(today, when);
      let tooltip = "";
      let color = "";

      if (diffInDays < 0) {
        tooltip = "Faltam " + diffInDays * -1 + " dia(s) para o abate";
        color = "green";
      } else if (diffInDays >= 0 && diffInDays <= 7) {
        tooltip = "Data de abate epirada a " + diffInDays + " dia(s)";
        color = "orange";
      } else if (diffInDays > 7) {
        tooltip = "Data de abate epirada a " + diffInDays + " dia(s)";
        color = "red";
        this.hasExpiredLots = true;
      }

      return new CattleLotExtraInfo(item, tooltip, color);
    },
    getDifferenceInDays(date1: Date, date2: Date): number {
      const diffInDays = date1.getTime() - date2.getTime();
      return parseInt(String(diffInDays / (1000 * 60 * 60 * 24)));
    },
  },
  computed: {
    headerFiltered(): ItemDataTable[] {
      if (this.$vuetify.breakpoint.smAndDown) {
        return [
          this.headers[0],
          this.headers[1],
          this.headers[2],
          this.headers[3],
          this.headers[this.headers.length - 1],
        ];
      }
      return this.headers;
    },

    itemFiltered(): CattleLotExtraInfo[] {
      if (this.resourceFiltered()) {
        const display: CattleLotExtraInfo[] =
          this.resourceFiltered<CattleLot>().map((el) => {
            return this.insertColor(el);
          });
        return display;
      }
      return [];
    },
  },
});
